import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routesP = [{
		path: '/',
		name: 'Index',
		component: () => import('../views/pc/index.vue')
	},
	{
		path: '/serve',
		name: 'Serve',
		component: () => import('../views/pc/serve.vue')
	},
	{
		path: '/synopsis',
		name: 'Synopsis',
		component: () => import('../views/pc/synopsis.vue')
	},
	{
		path: '/shape',
		name: 'Shape',
		component: () => import('../views/pc/shape.vue')
	},
	{
		path: '/contactUs',
		name: 'ContactUs',
		component: () => import('../views/pc/contactUs.vue')
	},
	{
		path: '/dynamicState',
		name: 'DynamicState',
		component: () => import('../views/pc/dynamicState.vue')
	},
	{
		path: '/dynamicStateDetails',
		name: 'DynamicStateDetails',
		component: () => import('../views/pc/dynamicStateDetails.vue')
	},
	{
		path: '/expert',
		name: 'Expert',
		component: () => import('../views/pc/expert.vue')
	},
	
]

const routesM = [{
		path: '/',
		name: 'Index',
		component: () => import('../views/mobile/index.vue')
	},
	{
		path: '/shape',
		name: 'Shape',
		component: () => import('../views/mobile/shape.vue')
	},
	{
		path: '/serve',
		name: 'Serve',
		component: () => import('../views/mobile/serve.vue')
	},
	{
		path: '/dynamicState',
		name: 'DynamicState',
		component: () => import('../views/mobile/dynamicState.vue')
	},
	{
		path: '/dynamicStateDetails',
		name: 'DynamicStateDetails',
		component: () => import('../views/mobile/dynamicStateDetails.vue')
	},
	{
		path: '/synopsis',
		name: 'Synopsis',
		component: () => import('../views/mobile/synopsis.vue')
	},
	{
		path: '/contactUs',
		name: 'ContactUs',
		component: () => import('../views/mobile/contactUs.vue')
	},
	{
		path: '/expert',
		name: 'Expert',
		component: () => import('../views/mobile/expert.vue')
	},
]

var routes = [];
if ((navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	))) {
	routes = routesM

} else {
	routes = routesP
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router